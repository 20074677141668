// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


// dev environment
export const environment = {
  production: false,
  apiEndpoint: 'https://shelfit-development.azurewebsites.net/api',
  importExportBucket: 'gs://shelfitdb_development_import_export',
  exportInventoryFunction: 'https://us-central1-shelfitdb.cloudfunctions.net/exportInventory',
  importInventoryFunction: 'https://us-central1-shelfitdb.cloudfunctions.net/import_company_inventory',
  firebase: {
    apiKey: 'AIzaSyBYC1XiUR72vH22XiJ_1Jl90Pfg4z8BBuc',
    authDomain: 'shelfit-production.firebaseapp.com',
    projectId: 'shelfit-production',
    storageBucket: 'shelfit-production.appspot.com',
    messagingSenderId: '979080147028',
    appId: '1:979080147028:web:383db3a267bc070a28fdc1',
    measurementId: 'G-Q608NVH7CD'
  },
  googleAppLink: 'https://play.google.com/store/apps/details?id=com.shelfit.android',
  emailAppLink: 'mailto:support@shelfit.tech',
  whatsAppLink: 'https://wa.me/+972587956137',
  telegramAppLink: 'https://t.me/EnigmeXmax',
};

/* prod environment */
/*
export const environment = {
  production: true,
  importExportBucket: 'gs://shelfitdb_development_import_export',
  exportInventoryFunction: 'https://us-central1-shelfitdb.cloudfunctions.net/exportInventory',
  importInventoryFunction: 'https://us-central1-shelfitdb.cloudfunctions.net/import_company_inventory',
  firebase: {
    apiKey: "AIzaSyCqpMabv6SFxoqle4mzUxrldHqd-seXX-Y",
    authDomain: "shelfitdb.firebaseapp.com",
    projectId: "shelfitdb",
    storageBucket: "shelfitdb.appspot.com",
    messagingSenderId: "349639003563",
    appId: "1:349639003563:web:0be5d7224a132f9b3096ee",
    measurementId: "G-5QRKWR73SQ"
  }
};
*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
